<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="address">
            <div class="name">{{ data.deliveryAddress.consignee }} 12345678901</div>
            <div class="phone">{{ data.deliveryAddress.address }}</div>
        </div>
        <div class="titles">商品信息</div>
        <div class="item">
            <img :src="data.picUrl" alt="" />
            <div class="right">
                <div class="title">{{ data.goodsName }}</div>
                <div class="bottom">
                    <div class="jifen">{{ data.score }}积分</div>
                    <div class="numbers">X{{ data.quantity }}</div>
                </div>
            </div>
        </div>
        <div class="jifenCount">
            总计：
            <span>{{ data.score * data.quantity }}积分</span>
        </div>
    </div>
</template>

<script>
import { getOrderDetails } from '@/api';
export default {
    name: 'OrderDetail',
    data() {
        return {
            data: {},
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '个人中心',
                    to: { path: '/my', query: { tabs: '0' } },
                },
                {
                    title: '我的订单',
                    to: { path: '/my', query: { tabs: '3' } },
                },
                {
                    title: '订单详情',
                },
            ],
        };
    },
    created() {
        this.getOrderDetails();
    },

    methods: {
        getOrderDetails() {
            getOrderDetails(this.$route.query.id).then((res) => {
                console.log(res);
                this.data = res.data;
            });
        },
    },
};
</script>

<style scoped lang="less">
.address {
    padding: 20px 30px 50px 20px;
    background: white;
    display: flex;
    flex-direction: column;
    width: 25%;

    .name {
        font-weight: 500;
        font-size: 17px;
        line-height: 25px;
        color: rgba(0, 0, 0, 0.8);
    }

    .phone {
        margin-top: 2px;
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.5);
    }
}

.titles {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #333333;
    padding: 24px 0 32px 0;
}

.item {
    display: flex;
    padding: 24px;
    background: #fff;
    border: 1px solid #ebebeb;

    img {
        width: 120px;
        height: 120px;
        border-radius: 4px;
    }

    .right {
        flex: 1;
        padding-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 23px;
            color: #333333;
            width: 100%;
            .ellipsisLine(2);
        }

        .bottom {
            .jifen {
                font-weight: 700;
                font-size: 17px;
                line-height: 25px;
                color: #eda200;
            }

            .numbers {
                font-size: 16px;
                line-height: 23px;
                color: #666666;
                margin-top: 12px;
            }
        }
    }
}

.jifenCount {
    padding: 24px;
    text-align: right;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    background: #f6f6f6;
    color: #333333;
    margin-bottom: 90px;
    span {
        color: #eda200;
    }
}
</style>
